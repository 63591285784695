<template>
  <nav class="main-navbar">
    <a href="#!">Flisol Cancún 2022</a>
    <router-link to="/tickets" target="_blank" class="get-tickets">
      Entradas
    </router-link>
  </nav>
</template>

<script>
export default {};
</script>
