import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Fragment from "vue-fragment";
import "./assets/css/styles.scss";

import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDyfSU5WhspvnaWgG3nAS1pW8wzLsey0cM",
  authDomain: "flisol-755b9.firebaseapp.com",
  projectId: "flisol-755b9",
  storageBucket: "flisol-755b9.appspot.com",
  messagingSenderId: "606789143047",
  appId: "1:606789143047:web:21788d781697454a6178a2",
  measurementId: "G-G93J5ZKG91",
};

firebase.initializeApp(firebaseConfig);

Vue.use(Fragment.Plugin);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
