<template>
  <section>
    <Title content="Flisol Cancún es posible gracias a" />
    <div class="sponsors">
      <div>
        <a target="_blank" href="https://www.unicaribe.mx/">
          <img src="../assets/unicaribe.png" alt="" />
        </a>
      </div>
      <div>
        <a target="_blank" href="https://santisoluciones.com/">
          <img src="../assets/sponsor-santi.jpeg" alt="" />
        </a>
      </div>

      <div>
        <a target="_blank" href="https://www.azulweb.net/">
          <img src="../assets/sponsor-azul-web.png" alt="" />
        </a>
      </div>

      <div>
        <a target="_blank" href="https://www.knqun.com/">
          <img src="../assets/sponsor-knqun.png" alt="" />
        </a>
      </div>
      <div>
        <a target="_blank" href="https://weaditivo.com/">
          <img src="../assets/we-aditivo.jpeg" alt="" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import Title from "./Title.vue";
export default {
  components: { Title },
};
</script>
