<template>
  <section>
    <Title content="Agenda" />
    <a
      target="_blank"
      href="https://www.google.com/calendar/render?action=TEMPLATE&text=FLISOL+CANC%C3%9AN+2022&details=El+festival+latinoamericano+de+instalaci%C3%B3n+de+software+libre+%E2%80%9CFLISOL%E2%80%9D+es+el+evento+de+difusi%C3%B3n+orientado+a+la+filosof%C3%ADa+del+software+libre+m%C3%A1s+grande+de+Latinoam%C3%A9rica&location=Universidad+del+Caribe%2C+Esquina+Fraccionamiento%2C+Tabachines%2C+77528+Canc%C3%BAn%2C+Q.R.%2C+M%C3%A9xico&dates=20220429T170000Z%2F20220429T230000Z"
    >
      🗓️ Agregar a mi agenda</a
    >
    <br />
    <a target="_blank" href="https://www.unicaribe.mx/ubicacion">
      📍 Auditorio de la Universidad del Caribe, 🔗 como llegar.</a
    >
    <table class="table">
      <tr class="table__tr">
        <td>Horario</td>
        <td>Evento</td>
        <td>Speaker</td>
      </tr>
      <tr v-for="item in schedule" :key="item.event" class="table__tr">
        <td>{{ item.time }}</td>
        <td>{{ item.event }}</td>
        <td>{{ item.speaker }}</td>
      </tr>
    </table>
  </section>
</template>

<script>
import Title from "./Title.vue";

export default {
  components: { Title },
  data() {
    return {
      schedule: [
        {
          time: "12:00 - 12:30",
          event: "Recepción y networking",
          speaker: "",
        },
        {
          time: "12:30 - 13:00",
          event: "Bievenida y anuncios",
          speaker: "",
        },
        {
          time: "13:00 - 14:00",
          event: "Kubernetes in Production: Lessons Learned",
          speaker: "Luis Enrique Tejeda Rodriguez",
        },
        {
          time: "14:00 - 15:00",
          event: "OpenSource Tools para Estudiantes",
          speaker: "Henry Raygan",
        },
        {
          time: "15:00 - 16:00",
          event:
            "BD de Series de Tiempo y monitoreo de servidores con Software Libre",
          speaker: "Luis Fernando Cetina",
        },
        {
          time: "16:00 - 17:00",
          event: "Cracking IT interviews ",
          speaker: "Leandro Tec",
        },
        {
          time: "17:00 - 18:00",
          event: "OpenSource Devops Tools",
          speaker: "Ismael Jimenez",
        },
      ],
    };
  },
};
</script>
